import styled from 'styled-components';
import { Text as Paragraph, Title } from '../../components/Typography';

interface ITeaserImageProps {
  $type?: string;
}

export const Image = styled.div<ITeaserImageProps>`
  width: 100%;
  overflow: hidden;
  height: 248px;
  border-radius: 100%/100px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 768px) {
    width: 50%;
    height: auto;
    min-height: 500px;
    border-radius: 150px/100%;
  }
  ${(p: ITeaserImageProps) =>
    p.$type === 'product'
      ? `
      img {
        object-fit: contain;
      }
    `
      : ''}
`;
export const Teaser = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--theme-color-important, #fdf9f4);
  border-radius: 10px;
  overflow: hidden;
  @media (min-width: 768px) {
    flex-direction: row;
    max-height: 500px;
    border-radius: 16px;
    :nth-child(even) {
      flex-direction: row-reverse;
      ${Image} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    :nth-child(odd) {
      ${Image} {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  @media (min-width: 768px) {
    width: 50%;
    padding: 0 64px;
    text-align: left;
  }
`;
export const StyledTitle = styled(Title)`
  margin-top: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--theme-color-accent, inherit);
  overflow: hidden;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const StyledHeading = styled.span`
  color: var(--theme-color-neutral, inherit);
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;
export const StyledText = styled(Paragraph)`
  margin-top: 20px;
  color: var(--theme-color-neutral, inherit);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: pre-wrap;
  min-height: 84px;
  @media (min-width: 768px) {
    -webkit-line-clamp: 8;
  }
`;
export const StyledButton = styled.a`
  position: relative;

  > div {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 768px) {
    > div {
      left: 0;
      transform: translateX(0);
    }
  }
`;
