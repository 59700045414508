import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { ISliderVanillaProps } from './types';
import { CarouselSlideLeft, CarouselSlideRight, CarouselWrapper } from './styles';
import { Swiper as SwiperType } from 'swiper';

export const Slider = ({
  slidesPerView = 3.5,
  isFullWidth = false,
  isStaticArrows = false,
  enableDots = true,
  className = '',
  isOverflowHidden = true,
  children,
}: ISliderVanillaProps) => {
  const swiperRef = useRef<SwiperType | null>(null);

  const handlePrevArrowClick = () => {
    if (swiperRef.current) {
      if (!swiperRef.current.isBeginning) {
        swiperRef.current.slidePrev();
      }
    }
  };

  const handleNextArrowClick = () => {
    if (swiperRef.current) {
      if (!swiperRef.current.isEnd) {
        swiperRef.current.slideNext();
      }
    }
  };

  const shouldBeFullWidth = isFullWidth && (swiperRef.current?.slides.length ?? 0) > 1;

  return (
    <CarouselWrapper
      data-test="carousel-wrapper"
      className={className}
      $isOverflowHidden={isOverflowHidden}
      style={{ width: shouldBeFullWidth ? '100%' : undefined }}
    >
      {!isStaticArrows && <CarouselSlideLeft onClick={handlePrevArrowClick} />}
      <Swiper
        modules={[Pagination]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={20}
        slidesPerView={swiperRef.current?.slides.length === 1 ? 1 : slidesPerView}
        slidesPerGroup={3}
        loop={false}
        pagination={{
          el: '.swiper-pagination',
          clickable: enableDots,
        }}
        speed={800}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 24 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: slidesPerView, spaceBetween: 20 },
        }}
        className={className}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            className="pb-16"
            style={{ width: shouldBeFullWidth ? '100%' : undefined }}
          >
            {child}
          </SwiperSlide>
        ))}
        <div className="swiper-pagination"></div>
      </Swiper>
      {!isStaticArrows && <CarouselSlideRight onClick={handleNextArrowClick} />}
    </CarouselWrapper>
  );
};
